<template>
	<div class="employee_info" v-loading="loading">
		<div class="form_flex">
			<div class="form_item ">
				<div class="titles"><span>*</span>姓名</div>
				<div class="inputs1">
					{{info.name}}
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>人员_证件号码</div>
				<div class="inputs1">
					{{info.id_card}}
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>工作邮箱</div>
				<div class="inputs1">
					{{info.email}}
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>移动电话</div>
				<div class="inputs1">
					{{info.mobile}}
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>办公电话</div>
				<div class="inputs1">
					{{info.telephone}}
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>现居住地</div>
				<div class="inputs1">
					{{info.address}}
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>邮政编码</div>
				<div class="inputs1">
					{{info.postal_code}}
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>户籍地址</div>
				<div class="inputs1">
					{{info.native_address}}
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>通讯地址</div>
				<div class="inputs1">
					{{info.tx_address}}
				</div>
			</div>
			<div class="form_item ">
				<div class="titles"><span>*</span>紧急联系人</div>
				<div class="inputs1">
					{{info.jj_name}}
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>紧急联系人称谓</div>
				<div class="inputs1">
					{{info.jj_wc}}
				</div>
			</div>
			<div class="form_item form_bg">
				<div class="titles"><span>*</span>紧急联系人电话</div>
				<div class="inputs1">
					{{info.jj_mobile}}
				</div>
			</div>

			<div class="flex_btns">
				<div class="btns1" @click="handle_edit()">修改通讯录信息</div>
				<!-- <div class="btns2" @click="handle_whjl()">维护简历</div> -->
			</div>

		</div>


		<Newsletter_management_add data-title="新增表单" ref="Newsletter_management_add" @confirm="confirm_add">
		</Newsletter_management_add>

	</div>
</template>
<script>
	import Newsletter_management_add from "@/components/Newsletter_management/Newsletter_management_add.vue"; //新增

	export default {
		name: "tongxunlu-info",
		components: {
			Newsletter_management_add
		},
		data() {
			return {
				info: {

				},
				loading: false,

			};
		},
		computed: {},
		watch: {

		},
		created() {
			this.setView()
		},
		methods: {
			setView() {
				this.query_tongxunlu_detail()
			},
			// 通讯录详情
			query_tongxunlu_detail() {
				this.$api("getUserAddressBook", {
					user_id: this.$route.query.id
				}, "get").then((res) => {
					if (res.code == 200) {
						let data = res.data;
						this.info = data;
					}
				});
			},
			handle_edit() {
				this.$refs.Newsletter_management_add.handle_open(this.info)
				// this.$router.push({
				// 	path: '/Employee_Information_add',
				// 	query: {
				// 		id: this.$route.query.id
				// 	}
				// })
			},
			confirm_add() {
				this.query_tongxunlu_detail()
			},

		},
	};
</script>

<style scoped lang="less">
	.employee_info {
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		padding: 0 9px;
		padding-bottom: 30px;

		.form_flex {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: space-between;
			padding-top: 57px;

			.form_item {
				width: 49.5%;
				display: flex;
				align-items: center;
				padding: 9px 0;

				.titles {
					width: 230px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					text-align: right;
					color: #4A4A4A;
					padding-right: 11px;

					span {
						color: #FF0000;
						padding-right: 8px;
					}
				}

				.inputs1 {
					width: 420px;
					height: 40px;
					border-radius: 0px 0px 0px 0px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #333333;
					display: flex;
					align-items: center;
				}
			}

			.form_bg {
				background: #F9F9F9;
			}

			.flex_btns {
				width: 100%;
				display: flex;
				align-items: center;
				padding-left: 230px;
				padding-top: 55px;

				.btns1 {
					width: 179px;
					height: 44px;
					background: #2373C8;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
					text-align: center;
					line-height: 44px;
					cursor: pointer;
				}

				.btns2 {
					width: 179px;
					height: 44px;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #9D9D9D;
					cursor: pointer;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #4A4A4A;
					text-align: center;
					line-height: 44px;
					margin-left: 18px;
				}
			}


		}
	}
</style>